import { Type } from '../../../../../store/constants/type'
import {
  addDataToSave,
  addTmpData,
  alertCopyPastError,
  removeDataToSave,
} from '../../../../../store/actions/tableur-action'
import { getExcelValueToSave } from './cell/list/tableur-cell-list'
import TableCell from '@material-ui/core/TableCell'
import { ConfirmMessage, SUPPRESSION } from './confirm-message'
import React from 'react'
import { isGroup, isListExclusion } from '../../../../../store/constants/type-helper'
import moment from 'moment'
import { TROIS_POINTS } from './cell/past-helper'

export function validatePastedValue(
  c,
  value,
  dispatch,
  line,
  parentPath,
  referentiels,
  conventions,
  oldCC,
  parent,
  role,
  legalOutputs
) {
  const componentName = parent ? parent.nom : c.nom
  switch (c.type) {
    case Type.NUMERIC:
      if (
        (isNaN(value) && role === 'inputs') ||
        (isNaN(value) && value !== '#LEGAL') ||
        (isNaN(value) &&
          value === '#LEGAL' &&
          !legalOutputs.find(
            comp =>
              comp.nom === componentName || (comp.enfants && comp.enfants.find(child => child.nom === componentName))
          ))
      )
        Promise.resolve().then(() => dispatch(alertCopyPastError(parentPath, componentName, value, line)))
      break
    case Type.DATE:
      if ( value && (value.length !== 10 || !moment(value, 'DD/MM/YYYY').isValid())) {
        dispatch(alertCopyPastError(parentPath, componentName, value, line))
      }
      break
    case Type.LIST_EXCLUSION:
      if (!value) return
      let val = value
        .replace(',', '')
        .replace('>', '')
        .split('<')
      if (!val || !val.length) break
      if (val.length > 1 && val[0] && val[1])  dispatch(alertCopyPastError(parentPath, componentName, val[0] + '<' + val[1] + '>', line))
      const valTab = [...val[0].split(';'), ...(val[1] ? val[1].split(';') : [])]
      if (valTab.length){
        if(val[0].split(';').find(v => val[1].split(';').includes(v))){
          dispatch(alertCopyPastError(parentPath, componentName, value, line))
        } else {
          valTab.forEach(v => {
            validatePastedValue(c.unit, v, dispatch, line, parentPath, referentiels, conventions, oldCC, c)
          })
        }
      }
      break
    case Type.LIST:
      if (!value) return
      if(c.unit.type === Type.TEXT && value.find(v => v.includes(TROIS_POINTS))){
        dispatch(alertCopyPastError(parentPath, componentName, value, line))
          break
      }
      value.forEach(v => {
        validatePastedValue(c.unit, v, dispatch, line, parentPath, referentiels, conventions, oldCC, c)
      })
      break
    case Type.REFERENCE:
      if (!value) return
      const r = referentiels.find(r => r.nom === c.referentiel)
      const v = r.valeurs.find(r => r.code === value)
      if (!v) {
        dispatch(alertCopyPastError(parentPath, componentName, value, line))
      }
      break
    case Type.TEXT:
      if (componentName === 'idCC') {
        const hasValue = value && conventions.find(c => c.idcc === value)
        const idccNotChange = !oldCC || (value && oldCC === value)
        if (!hasValue || !idccNotChange || !value) dispatch(alertCopyPastError(parentPath, componentName, value, line))
      } else if (
        value === '#LEGAL' &&
        !legalOutputs.find(
          comp =>
            comp.nom === componentName || (comp.enfants && comp.enfants.find(child => child.nom === componentName))
        )
      )
        dispatch(alertCopyPastError(parentPath, componentName, value, line))
      break
    default:
      return
  }
}

export function validateData(
  value,
  key,
  c,
  dispatch,
  line,
  parentPath,
  role,
  referentiels,
  conventions,
  oldCC,
  legalOutputs
) {
  validatePastedValue(
    c,
    value[key],
    dispatch,
    line,
    parentPath,
    referentiels,
    conventions,
    oldCC,
    '',
    role,
    legalOutputs
  )
  return value[key]
}

export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object))
}

export function isAnObject(value) {
  return !Array.isArray(value) && typeof value === 'object'
}

function clearValueFromObject(newTmpDataElement) {
  if (newTmpDataElement.key) {
    delete newTmpDataElement.key
    delete newTmpDataElement.refType
  }
}

function createEmptyExclusionIfNeeded(key, data) {
  const other = key === 'inclus' ? 'exclus' : 'inclus'
  if (!data[other]) data[other] = []
  return data
}

function setGroupDataValue(c, data, parentSplit, key, value, i = 0) {
  if (i < parentSplit.length) {
    if (!data[parentSplit[i]]) data[parentSplit[i]] = {}
    clearValueFromObject(data[parentSplit[i]])
    data[parentSplit[i]] = setGroupDataValue(c, data[parentSplit[i]], parentSplit, key, value, i + 1)
    return data
  }
  data[key] = value
  if (isListExclusion(c.parentType)) data = createEmptyExclusionIfNeeded(key, data, parentSplit, i)
  return data
}

function createGroupData(c, newTmpData, role, key, value) {
  const parentSplit = c.parentPath.split('.')
  newTmpData[role] = setGroupDataValue(c, newTmpData[role], parentSplit, key, value)
  return newTmpData
}

// export function createNewTmpData(value, newTmpData, role, legalOutputs, key, c, dispatch, line, referentiels, conventions, oldCC) {
export function createNewTmpData(
  value,
  newTmpData,
  role,
  key,
  c,
  dispatch,
  line,
  referentiels,
  conventions,
  oldCC,
  legalOutputs,
  fromCopyPast
) {
  if (role && c.parentPath) {
    if (fromCopyPast)
      validatePastedValue(
        c,
        value,
        dispatch,
        line,
        c.getComponentPath(),
        referentiels,
        conventions,
        oldCC,
        '',
        role,
        legalOutputs
      )
    return createGroupData(
      c,
      newTmpData,
      role,
      isListExclusion(c.parentType) ? (c.exclude ? 'exclus' : 'inclus') : key,
      value
    )
  }
  if (isAnObject(value)) {
    if (role) {
      newTmpData[role][key] = {}
      Object.keys(value).forEach(k => {
        newTmpData[role][key][k] = isAnObject(value[k])
          ? createNewTmpData(
              value[k],
              {},
              '',
              k,
              c.enfants.find(e => e.nom === k),
              dispatch,
              line,
              referentiels,
              conventions,
              oldCC,
              legalOutputs
            )
          : validateData(
              value,
              k,
              c.enfants.find(e => e.nom === k),
              dispatch,
              line,
              c.getComponentPath(),
              role,
              referentiels,
              conventions,
              '',
              legalOutputs
            )
      })
    } else {
      Object.keys(value).forEach(k => {
        newTmpData[k] = isAnObject(value[k])
          ? createNewTmpData(
              value[k],
              {},
              '',
              k,
              c.enfants.find(e => e.nom === k),
              dispatch,
              line,
              referentiels,
              conventions,
              oldCC,
              legalOutputs
            )
          : validateData(
              value,
              k,
              c.enfants.find(e => e.nom === k),
              dispatch,
              line,
              c.getComponentPath(),
              role,
              referentiels,
              conventions,
              '',
              legalOutputs
            )
      })
    }
  } else {
    if (role) newTmpData[role][key] = value
    else newTmpData[key] = value
    validatePastedValue(
      c,
      value,
      dispatch,
      line,
      c.parentPath,
      referentiels,
      conventions,
      oldCC,
      '',
      role,
      legalOutputs
    )
    // validatePastedValue(c, value, dispatch, line, c.parentPath, referentiels, conventions, oldCC)
  }
  return newTmpData
}

export function createDatatoSave(component, dataToSave, role, key, value) {
  if (component && isListExclusion(component.type)) {
    if (role) dataToSave[role][key] = getExcelValueToSave(value, component)
    else dataToSave = getExcelValueToSave(value, component)
  } else if (isAnObject(value)) {
    Object.keys(value).forEach(k => {
      const c = component.enfants.filter(e => e.nom === k).find(e => isListExclusion(e.type) || isGroup(e.type))
      if (c) {
        if (role)
          dataToSave[role][key][k] = isAnObject(value[k])
            ? createDatatoSave(c, dataToSave[role][key][k], '', k, value[k])
            : getExcelValueToSave(value[k], c)
        else {
          dataToSave[k] = isAnObject(value[k])
            ? createDatatoSave(c, dataToSave[k], '', k, value[k])
            : getExcelValueToSave(value[k], c)
        }
      }
    })
  }
  return dataToSave
}

export function handleTmpDataExtracted(
  myTmpData,
  tmpData,
  data,
  value,
  isInput,
  key,
  component,
  dispatch,
  line,
  referentiels,
  conventions,
  oldCC,
  legalOutputs
) {
  let newTmpData = myTmpData ? myTmpData : tmpData ? cloneObject(tmpData) : cloneObject(data)
  const newTmpData1 = createNewTmpData(
    value,
    newTmpData,
    isInput ? 'inputs' : 'outputs',
    key,
    component,
    dispatch,
    line,
    referentiels,
    conventions,
    oldCC,
    legalOutputs
  )
  if (!myTmpData) dispatch(addTmpData(newTmpData1))
  const dataToSave = cloneObject(newTmpData1)
  if (!myTmpData) {
    const toSave = createDatatoSave(component, dataToSave, isInput ? 'inputs' : 'outputs', key, value)
    if (dataToSave !== data) dispatch(addDataToSave(toSave))
    else dispatch(removeDataToSave(toSave.id))
  }
}

export const getParentName = path => {
  if (!path) return ''
  const split = path.split('.')
  return split[split.length - 1]
}

export function getChildrenData(tmpData, c) {
  let res = tmpData
  if (c.parentPath)
    c.parentPath.split('.').forEach(k => {
      if (!res) return false
      res = res[k]
    })
  if (res) return res[c.nom]
}

export function showMessage(isInput, activeComponents, idx, handleDelete, handleCancel) {
  return isInput ? (
    <TableCell colSpan={activeComponents.length}>
      <ConfirmMessage
        dataTest={'message_line_' + idx}
        onClick={handleDelete}
        onClick1={handleCancel}
        action={SUPPRESSION}
      />
    </TableCell>
  ) : (
    <TableCell />
  )
}

export function getNextNonUnitComp(currentComponent, idxComponenent, activeComponents, c) {
  if (
    currentComponent &&
    currentComponent.parentType &&
    currentComponent.parentType.name === Type.LIST_EXCLUSION.name
  ) {
    idxComponenent = idxComponenent + 2
    currentComponent = activeComponents[activeComponents.indexOf(c) + idxComponenent]
  }
  return [currentComponent, idxComponenent]
}

export function isCurrentComponentIsAnotherGroup(currentComponent, currentGroup) {
  return (
    currentComponent &&
    currentComponent.type === Type.GROUP &&
    currentComponent !== currentGroup &&
    currentComponent.parentPath.split('.')[0] === currentGroup.nom
  )
}

export function isChildGroup(currentGroup, tmpGroup) {
  return currentGroup.enfants.length && currentGroup.enfants[tmpGroup.length - 1].type === Type.GROUP
}

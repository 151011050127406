export default class Access {
  client
  ccs
  themes
  apiCCAccess
  apiRetextAccess

  constructor(client = '', ccs = {}, themes = {}, apiCCAccess = false, apiRetextAccess = false) {
    this.client = client
    this.ccs = ccs
    this.themes = themes
    this.apiCCAccess = apiCCAccess
    this.apiRetextAccess = apiRetextAccess
  }

  static NULL = new Access()
}

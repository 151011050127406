export const LOGIN_ENDPOINT = {
  endpoint: 'signin',
  sexe: 'F',
  singulier: 'authentification',
  pluriel: 'authentifications',
}

export const LOGOUT_ENDPOINT = {
  endpoint: 'signout',
  sexe: 'F',
  singulier: 'authentification',
  pluriel: 'authentifications',
}

export const USER_ENDPOINT = {
  endpoint: 'user',
  sexe: 'M',
  singulier: 'user',
  pluriel: 'user',
}

export const TASK_ENDPOINT = {
  endpoint: 'tasks',
  sexe: 'F',
  singulier: 'tâche',
  pluriel: 'tâches',
}

export const LOG_ENDPOINT = {
  endpoint: 'logs',
  sexe: 'F',
  singulier: 'log',
  pluriel: 'logs',
}

export const CC_ENDPOINT = {
  endpoint: 'conventions-collectives',
  sexe: 'F',
  singulier: 'convention collective',
  pluriel: 'conventions collectives',
}
export const SETTINGS_ENDPOINT = {
  endpoint: 'settings',
  sexe: 'M',
  singulier: 'paramètre',
  pluriel: 'paramètres',
}
export const CC_ENDPOINT_CHECKDELETION = {
  endpoint: 'conventions-collectives/checkCCBeforDelete',
  sexe: 'F',
  singulier: 'convention collective',
  pluriel: 'conventions collectives',
}
export const ACCESS_ENDPOINT = {
  endpoint: 'access',
  sexe: 'M',
  apostrophe: true,
  singulier: 'accès',
  pluriel: 'accès',
}
export const CC_THEME_ENDPOINT = {
  endpoint: 'conventions-collectives_themes',
  sexe: 'M',
  singulier: 'thème',
  pluriel: 'thèmes',
}
export const CC_THEME_DATA_ENDPOINT = {
  endpoint: 'conventions-collectives_themes_data',
  sexe: 'F',
  singulier: 'données',
  pluriel: 'données',
}

export const CC_THEME_INPUTS_ENDPOINT = {
  endpoint: 'conventions-collectives_themes_inputs',
  sexe: 'M',
  singulier: 'composant pour les thèmes de la convention collective',
  pluriel: 'composants pour les thèmes de la convention collective',
}
export const DATA_ENDPOINT = {
  endpoint: 'data',
  sexe: 'F',
  singulier: 'donnée',
  pluriel: 'données',
}

export const STATUT_ENDPOINT = {
  endpoint: 'status',
  sexe: 'M',
  singulier: 'statut',
  pluriel: 'statuts',
}
export const CC_THEME_OUTPUTS_ENDPOINT = {
  endpoint: 'conventions-collectives_themes_outputs',
  sexe: 'M',
  singulier: 'composant pour les thèmes de la convention collective',
  pluriel: 'composants pour les thèmes de la convention collective',
}
export const THEME_ENDPOINT = {
  endpoint: 'themes',
  sexe: 'M',
  singulier: 'thème',
  pluriel: 'thèmes',
}
export const TYPE_ENDPOINT = {
  endpoint: 'types',
  sexe: 'M',
  singulier: 'type',
  pluriel: 'types',
}
export const THEME_INPUTS_ENDPOINT = {
  endpoint: 'themes_inputs',
  sexe: 'M',
  singulier: 'composant pour les thèmes',
  pluriel: 'composants pour les thèmes',
}
export const THEME_OUTPUTS_ENDPOINT = {
  endpoint: 'themes_outputs',
  sexe: 'M',
  singulier: 'composant pour les thèmes',
  pluriel: 'composants pour les thèmes',
}
export const VERSION_ENDPOINT = { endpoint: 'versions', sexe: 'F', singulier: 'version', pluriel: 'versions' }
export const VERSION_NEXT_ENDPOINT = {
  endpoint: 'versions/next-version',
  sexe: 'F',
  singulier: 'version',
  pluriel: 'versions',
}
export const VERSION_LAST_ENDPOINT = {
  endpoint: 'versions/last',
  sexe: 'F',
  singulier: 'version',
  pluriel: 'versions',
}
export const VERSION_LATEST_ENDPOINT = {
  endpoint: 'versions/latest',
  sexe: 'F',
  singulier: 'version',
  pluriel: 'versions',
}

export const VERSION_LAST_PUBLISHED_ENDPOINT = {
  endpoint: 'versions/last-published',
  sexe: 'F',
  singulier: 'version',
  pluriel: 'versions',
}

export const VERSION_THEME_ENDPOINT = {
  endpoint: 'versions_themes',
  sexe: 'M',
  singulier: 'thème',
  pluriel: 'thèmes',
}

export const CHANGELOGS_ENDPOINT = {
  endpoint: 'changelogs',
  sexe: 'M',
  singulier: 'historique',
  pluriel: 'historiques',
}

export const VERSION_CHANGELOGS_ENDPOINT = {
  endpoint: 'versions_changelogs',
  sexe: 'M',
  singulier: 'historique du thème',
  pluriel: 'historique des thèmes',
}

export const THEME_ENDPOINT_PUBLISH_VERSION = {
  endpoint: 'themes/activate/version',
  sexe: 'F',
  singulier: 'version',
  pluriel: 'versions',
}
export const COMPONENTS_ENDPOINT = { endpoint: 'components', sexe: 'M', singulier: 'composant', pluriel: 'composants' }
// export const COMPONENTS_CANBEUPDATED_ENDPOINT = { endpoint: 'components/canBeUpdated', sexe: 'M', singulier: 'composant', pluriel: 'composants' }
export const USED_COMPONENTS_ENDPOINT = { endpoint: 'used-components', sexe: 'M', singulier: 'composant', pluriel: 'composants' }
export const TRANSFORMATION_RULE_ENDPOINT = {
  endpoint: 'transformation-rules',
  sexe: 'M',
  singulier: 'transformation',
  pluriel: 'transformations',
}
export const INPUT_ENDPOINT = {
  endpoint: 'inputs',
  sexe: 'M',
  singulier: 'composant',
  pluriel: 'composants',
}
export const OUTPUT_ENDPOINT = {
  endpoint: 'outputs',
  sexe: 'M',
  singulier: 'composant',
  pluriel: 'composants',
}
export const IMPORT_DATA_ENDPOINT = { endpoint: 'import-data', sexe: 'M', singulier: 'import', pluriel: 'imports' }
export const IMPORT_TEXTUALISATION_ENDPOINT = { endpoint: 'import-textualisation', sexe: 'M', singulier: 'import', pluriel: 'imports' }
export const IMPORT_WORKFLOW_ENDPOINT = { endpoint: 'import-workflow/model', sexe: 'M', singulier: 'import', pluriel: 'imports' }
export const IMPORT_BLOCKS_ENDPOINT = { endpoint: 'import-workflow', sexe: 'M', singulier: 'import', pluriel: 'imports' }
export const CC_THEME_INPUTS_VALUES_ENDPOINT = {
  endpoint: 'conventions-collectives_themes_inputs_values',
  sexe: 'M',
  singulier: 'composant en entrée',
  pluriel: 'composants en entrée',
}
export const CC_THEME_OUTPUTS_VALUES_ENDPOINT = {
  endpoint: 'conventions-collectives_themes_outputs_values',
  sexe: 'M',
  singulier: 'composant en sortie',
  pluriel: 'composants en sortie',
}
export const REFERENTIEL_ENDPOINT = {
  endpoint: 'referentials',
  sexe: 'M',
  singulier: 'référentiel',
  pluriel: 'référentiels',
}
export const REFERENTIEL_VALUES_ENDPOINT = {
  endpoint: 'referentials_values',
  sexe: 'F',
  singulier: 'valeur du référentiel',
  pluriel: 'valeurs du référentiel',
}
export const HISTORIQUE_ENDPOINT = {
  endpoint: 'historiques',
  sexe: 'M',
  singulier: 'historique',
  pluriel: 'historiques',
}

export const CC_HISTORIQUE_ENDPOINT = {
  endpoint: 'conventions-collectives_historiques',
  sexe: 'M',
  singulier: 'historique',
  pluriel: 'historiques',
}

export const MODELE_WORKFLOW_ENDPOINT = {
  endpoint: 'modele-workflow',
  sexe: 'M',
  singulier: 'modèle workflow',
  pluriel: 'modèles workflows',
}


export const BLOCKS_ENDPOINT = {
  endpoint: 'blocks',
  sexe: 'M',
  singulier: 'textualisation',
  pluriel: 'textualisations',
}

export const WORKFLOW_ENDPOINT = {
  endpoint: 'workflow',
  sexe: 'M',
  singulier: 'workflow',
  pluriel: 'workflow',
}

export const WORKFLOW_STATUS_ENDPOINT = {
  endpoint: 'workflow/status',
  sexe: 'M',
  singulier: 'workflow',
  pluriel: 'workflow',
}

export const WORKFLOW_VALIDATION_ENDPOINT = {
  endpoint: 'workflow/validate',
  sexe: 'M',
  singulier: 'workflow',
  pluriel: 'workflow',
}

export const WORKFLOW_CANCEL_ENDPOINT = {
  endpoint: 'workflow/cancel',
  sexe: 'M',
  singulier: 'workflow',
  pluriel: 'workflow',
}

export const HELPER_ENDPOINTS = {
  helper: {
    isFeminin: endpoint => {
      return endpoint.sexe === 'F'
    },
    isApostrophe: endpoint => {
      return endpoint.apostrophe ? true : false
    },
    e: (endpoint, plural) => {
      return endpoint.helper.isFeminin(endpoint) ? (plural ? 'es' : 'e') : plural ? 's' : ''
    },
    d: (endpoint, plural) => {
      return plural
        ? 'les'
        : endpoint.helper.isApostrophe(endpoint)
        ? "l'"
        : endpoint.helper.isFeminin(endpoint)
        ? 'la'
        : 'le'
    },
  },
}

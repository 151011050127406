import { ModelWorkflow, TypeModele } from '../constants/ModelWorkflow'
import { ModelFormField } from '../constants/ModelFormField'
import { ModelRefField, OperationUnit } from '../constants/ModelRefField'
import { ModelNewValue } from '../constants/ModelNewValue'
import { ModelReplacement } from '../constants/ModelReplacement'
import { TypeFormField } from '../constants/TypeFormField'
import { getTypeFromString } from '../constants/type'

import { SET_BLOCKS, SET_MODELS, SET_WORKFLOW, SET_WORKFLOWS } from '../reducers/workflow-reducer'
import getPayeService from '../../api/envService'
import {
  BLOCKS_ENDPOINT,
  CC_ENDPOINT,
  MODELE_WORKFLOW_ENDPOINT,
  THEME_ENDPOINT,
  WORKFLOW_ENDPOINT,
  WORKFLOW_STATUS_ENDPOINT,
  WORKFLOW_VALIDATION_ENDPOINT,
} from '../../api/endpoints'
import { setLoader, setTableurLoader } from './loader-action'
import { getThemes } from './theme-action'
import { loadComponents } from './component-action'
import { getAllReferentiels } from './referentiel-action'
import { loadData } from './tableur-action'
import { ModelInstanceWorkflow, WorkflowStatut } from './../constants/ModelInstanceWorkFlow'
import _ from 'lodash'
import { getCCTheme } from './convention-collective-action'
import { ModelTextualisation } from '../constants/ModelTextualisation'
import { TextParam } from '../constants/TextParam'
import { TextParamType } from '../constants/TextParamType'
import { TextBlock } from '../constants/TextBlock'
import { TextFragment } from '../constants/TextFragment'
import { TextFragmentType } from '../constants/TextFragmentType'
import { TextBlockType } from '../constants/TextBlockType'
import { ConditionalText } from '../constants/ConditionalText'
import { Blocks } from '../constants/Blocks'

export const buildModelWorkflowFromJson = data => new ModelWorkflow(data.nom, data.description, data.type)

function getModelType(d) {
  return d.type.name ? TypeModele.fromStr(d.type.name) : TypeModele.fromStr(d.type)
}

function buildTextBlock(b) {

  return new TextBlock(
    b.fragments.map(f => new TextFragment(TextFragmentType.fromStr(f.type), f.text, f.component)),
    TextBlockType.fromStr(b.type),
    b.pluralFragments.map(f => new TextFragment(TextFragmentType.fromStr(f.type), f.text, f.component)),
    b.uniqValueOnly,
    b.separable,
    b.children.map(c => buildTextBlock(c)),
    b.conditionalTexts.map(c => buildConditionalText(c))
  )
}

function buildConditionalText(c) {
  return new ConditionalText(
    c.name,
    c.currentValue,
    c.readCurrentValue,
    c.compValues,
    c.shouldRemove,
    buildTextBlock(c.block)
  )
}

function buildTextualisation(d) {
  if (!d) return
  return new ModelTextualisation(
    d.parameters.map(p => new TextParam(p.component, TextParamType.fromStr(p.type)))
  )
}


function buildBlocks(d) {
  if (!d) return
  return new Blocks(
    d.blocks.map(b => buildTextBlock(b)),
    d.sortedPartNames
  )
}
export const updateBlocks = (blocks) => dispatch => {
  Promise.resolve()
    .then(() => getPayeService().put(BLOCKS_ENDPOINT, blocks))
    .then(() => dispatch(getBlocks()))
    .catch(e => {
      console.log(e)
    })
}


export const buildModelsWorkflowsFromJson = data => {
  return data.map(
    d =>
      new ModelWorkflow(
        d.nom ? d.nom : d.name,
        d.description,
        getModelType(d),
        d.clearOutput,
        buildFormFieldsFromJson(d.formFields),
        buildRefFieldsFromJson(d.refFields),
        buildNewValuesFromJson(d.newValues),
        buildRefFieldsFromJson(d.doublons),
        buildTextualisation(d.textualisation)
      )
  )
}
const formatDate = date => {
  if (date) return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
  else return ''
}
export const createWF = (idcc, instanceWF, success, failure) => dispatch => {
  const data = { ...instanceWF }
  data.dateValidation = formatDate(data.dateValidation)
  Promise.resolve()
    .then(() => getPayeService().post(WORKFLOW_ENDPOINT, data))
    .then(() => dispatch(getCCWorkflows(idcc)))
    .then(() => success && success())
    .catch(e => {
      console.log(e)
      failure && failure()
    })
}

export function getTypeForm(form) {
  return form.typeForm && form.typeForm.name
    ? TypeFormField.fromStr(form.typeForm.name)
    : TypeFormField.fromStr(form.typeForm)
}

function buildFormFieldsFromJson(formFields) {
  if (formFields)
    return formFields.map(
      form =>
        new ModelFormField(
          form.field,
          getTypeForm(form),
          form.required,
          form.workflowRef,
          form.workflowField,
          form.referentielName
        )
    )
}

export function buildWorkflowFromJson(workflow) {
  const statut = {}
  workflow.themes.forEach(
    t =>
      (statut[t.toUpperCase()] = WorkflowStatut.fromStr(
        workflow.statut[t.toUpperCase()].name ? workflow.statut[t.toUpperCase()].name : workflow.statut[t.toUpperCase()]
      ))
  )
  const parseDate = str => {
    if (!str) return null
    if (_.isString(str)) {
      const val = str.split('/')
      return new Date(parseInt(val[2]), parseInt(val[1]) - 1, parseInt(val[0]))
    }
    if (_.isNumber(str)) {
      return new Date(str)
    }
    return null
  }
  return new ModelInstanceWorkflow(
    workflow.nom,
    workflow.description,
    workflow.referenceAccord,
    statut,
    workflow.themes,
    workflow.themesInputType,
    workflow.fieldsInputType,
    workflow.idcc,
    workflow.modelWorkflow,
    parseDate(workflow.dateValidation),
    workflow.workflowRef,
    workflow.user,
    workflow.source,
    workflow.parameters ? workflow.parameters.map(p => new TextParam(p.component, TextParamType.fromStr(p.type))) : []
  )
}

export function buildWorkflowsFromJson(workflows) {
  if (workflows) return workflows.map(workflow => buildWorkflowFromJson(workflow))
}

function getUnit(data) {
  return data.newValue.operationUnit.name
    ? data.newValue.operationUnit
    : OperationUnit.fromStr(data.newValue.operationUnit)
}

function buildRefFieldsFromJson(formFields) {
  if (formFields) {
    return formFields.map(data => {
      let newValue
      if (data.newValue) {
        let unit
        if (data.newValue.operationUnit) unit = getUnit(data)
        newValue = new ModelReplacement(data.newValue.value, data.newValue.refField, data.newValue.operation, unit)
      }
      return new ModelRefField(
        getTypeFromString(data.type),
        data.component,
        data.targetOperator,
        data.targetValue,
        newValue,
        data.selectedTargetValue
      )
    })
  }
}

function buildNewValuesFromJson(newValues) {
  if (newValues) {
    return newValues.map(data => {
      let newValue
      if (data.newValue) {
        let unit
        if (data.newValue.operationUnit) unit = getUnit(data)
        newValue = new ModelReplacement(data.newValue.value, data.newValue.refField, data.newValue.operation, unit)
      }
      return new ModelNewValue(getTypeFromString(data.type), data.component, newValue)
    })
  }
}

export const setModels = models => {
  return { type: SET_MODELS, value: models }
}

export const setBlocks = models => {
  return { type: SET_BLOCKS, value: models }
}

export const getModels = () => dispatch => {
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().get(MODELE_WORKFLOW_ENDPOINT))
    .then(data => {
      const result = buildModelsWorkflowsFromJson(data)
      return result
    })
    .then(models => dispatch(setModels(models)))
    .finally(() => dispatch(setLoader()))
}

export const getBlocks = () => dispatch => {
  Promise.resolve()
    .then(() => getPayeService().get(BLOCKS_ENDPOINT))
    .then(data => buildBlocks(data))
    .then(models => dispatch(setBlocks(models)))
}

export const setWorkflow = workflow => {
  return { type: SET_WORKFLOW, value: workflow }
}

export const annulerWorkflow = nextTheme => (dispatch, state) => {
  const workflow = state().workflowModule.workflow
  const themeName = state().conventionModule.theme.nom
  const themes = state().conventionModule.themes
  const idcc = state().conventionModule.conventionCollective.idcc
  return Promise.resolve()
    .then(() => dispatch(setTableurLoader(true)))
    .then(() => {
      return getPayeService().deleteChild(CC_ENDPOINT, idcc, THEME_ENDPOINT, themeName, WORKFLOW_ENDPOINT, workflow.nom)
    })
    .then(() => {
      if (nextTheme) {
        const themesWithWF = themes
          .filter(t => t.nom != themeName)
          .filter(t => filterWorkflowsByTheme(t.nom, [workflow]).length > 0)
        if (themesWithWF.length > 0) {
          nextTheme(themesWithWF[0].nom)
        }
      }
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() =>
      Promise.resolve()
        .then(() => dispatch(loadData(themeName)))
        .then(() => dispatch(getCCWorkflows(idcc)))
        .then(() => dispatch(setWorkflow(ModelInstanceWorkflow.NULL)))
        .then(() => dispatch(setTableurLoader(false)))
    )
}

export const filterWorkflowsByTheme = (themeName, workflows) => {
  return !!workflows
    ? workflows.filter(
        wf => wf.statut[themeName.toUpperCase()] && wf.statut[themeName.toUpperCase()] === WorkflowStatut.EN_COURS
      )
    : []
}

export const validateWorkflow = nextTheme => (dispatch, state) => {
  const workflow = state().workflowModule.workflow
  const themeName = state().conventionModule.theme.nom
  const themes = state().conventionModule.themes
  const idcc = state().conventionModule.conventionCollective.idcc
  return Promise.resolve()
    .then(() => dispatch(setTableurLoader(true)))
    .then(() => {
      let validation = { workflow: workflow.nom, theme: themeName, idcc: idcc }
      return getPayeService().post(WORKFLOW_VALIDATION_ENDPOINT, validation)
    })
    .then(() => {
      if (nextTheme) {
        const themesWithWF = themes
          .filter(t => t.nom != themeName)
          .filter(t => filterWorkflowsByTheme(t.nom, [workflow]).length > 0)
        if (themesWithWF.length > 0) {
          nextTheme(themesWithWF[0].nom)
        }
      }
    })
    .then(() => dispatch(getCCTheme(idcc, themeName)))
    .then(() => dispatch(loadData(themeName)))
    .then(() => dispatch(getCCWorkflows(idcc)))
    .then(() => dispatch(setWorkflow(ModelInstanceWorkflow.NULL)))
    .catch(e => {
      console.log(e)
    })
    .finally(() => Promise.resolve().then(() => dispatch(setTableurLoader(false))))
}

// export const getWorkflow = (workflowName) => dispatch=>{
//   Promise.resolve()
//     .then(()=>dispatch(setLoader(true)))
//     .then(() => getPayeService().getById(WORKFLOW_ENDPOINT,workflowName))
//     .then(data => {
//       return buildWorkflowFromJson(data)
//     })
//     .then(workflow => dispatch(setWorkflow(workflow)))
//     .then(()=>dispatch(setLoader(false)))
// }

export const setWorkflows = workflows => {
  return { type: SET_WORKFLOWS, value: workflows }
}

export const getInProgressWf = next => dispatch => {
  Promise.resolve()
    .then(() => getPayeService().getById(WORKFLOW_STATUS_ENDPOINT, 'IN_PROGRESS'))
    .then(data => buildWorkflowsFromJson(data))
    .then(workflows => {
      dispatch(setWorkflows(workflows))
      return workflows
    })
    .then(workflows => next && next(workflows))
}
export const getCCWorkflows = (idcc, next) => dispatch => {
  Promise.resolve()
    .then(() => getPayeService().getChildren(CC_ENDPOINT, idcc, WORKFLOW_ENDPOINT))
    .then(data => {
      const result = buildWorkflowsFromJson(data)
      return result
    })
    .then(workflows => {
      dispatch(setWorkflows(workflows))
      return workflows
    })
    .then(workflows => next && next(workflows))
    .catch(e => console.error(e))
}

export const initWorkflowsConfiguration = () => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(getThemes(true)))
    .then(() => dispatch(getModels()))
    .then(() => dispatch(getBlocks()))
    .then(() => dispatch(getInProgressWf()))
    .then(() => dispatch(loadComponents(true)))
    .then(() => dispatch(getAllReferentiels()))
    .finally(() => dispatch(setLoader(false)))

import React,{useState,useEffect} from 'react'
import * as PropTypes from 'prop-types'
import {TableCell} from '@material-ui/core'
import InputText from '../common/input-text'
import CellActions from '../common/table/editable/renderer/cell-actions'

/**
 * Permet d'éditer et de modifier une valeur de type string
 */
export default function RowAddEditText({dataTestKey,initialValue,creation,dataTestPrefix,save,check,onChange,onSave,onCancel}) {
    const [updateAvailable,setUpdateAvailable] = useState(true)
    const [editedValue,setEditedValue] = useState(initialValue)

    const handleSave = () => {
        setUpdateAvailable(false)
        if(onSave) {
            if(!onSave(editedValue)){
                setUpdateAvailable(true)
            }
        }
    }

    const handleCancel = () => {
        setEditedValue(null)
        setUpdateAvailable(false)
        if(onCancel) onCancel()
    }

    const handleCodeChange = (e,newVal) => {
        let val = {code:newVal}
        if(editedValue){
            val.libelle=editedValue.libelle
            val.text=editedValue.text
        }
        setEditedValue(val)
        if(onChange)
            onChange(val)
    }

  const handleLibelleChange = (e,newVal) => {
    let val = {libelle:newVal}
    if(editedValue){
      val.code=editedValue.code
      val.text=editedValue.text
    }
    setEditedValue(val)
    if(onChange)
      onChange(val)
  }

  const handleTextChange = (e,newVal) => {
    let val = {text:newVal}
    if(editedValue){
      val.code=editedValue.code
      val.libelle=editedValue.libelle
    }
    setEditedValue(val)
    if(onChange)
      onChange(val)
  }

    useEffect(()=>{
        setEditedValue(initialValue)
    },[initialValue])

    return (
        <>
            <TableCell key="1" style={{textAlign:'left'}}>
                {creation && (<InputText
                            initialValue={initialValue.code}
                            dataTest={dataTestPrefix+"_edit_code_"+(initialValue==null ? "" : ""+initialValue.code)}
                            onChange={(e,newVal)=>handleCodeChange(e,newVal)}
                            dataKey={dataTestKey}
                            value={editedValue}
                            maxInputSize={25}
                            pattern={`^[a-zA-Z0-9]*$`}
                            style={{margin: 0, padding: 0}}
                            placeholder="Code"/>)}
                {!creation && (initialValue.code)}
            </TableCell>
          <TableCell key="2" style={{textAlign:'left'}}>
            <InputText
              initialValue={initialValue.libelle}
              dataTest={dataTestPrefix+"_edit_libelle_"+(initialValue==null ? "" : ""+initialValue.libelle)}
              onChange={(e,newVal)=>handleLibelleChange(e,newVal)}
              style={{margin: 0, padding: 0}}
              dataKey={dataTestKey}
              placeholder="Libellé"/>
          </TableCell>
          <TableCell key="3" style={{textAlign:'left'}}>
            <InputText
              initialValue={initialValue.text}
              dataTest={dataTestPrefix+"_edit_text_"+(initialValue==null ? "" : ""+initialValue.text)}
              onChange={(e,newVal)=>handleTextChange(e,newVal)}
              style={{margin: 0, padding: 0}}
              dataKey={dataTestKey}
              placeholder="Texte"/>
          </TableCell>
            <CellActions key="4"
                dataTestKey={dataTestKey}
                dataTestPrefix={dataTestPrefix}
                save={updateAvailable && save}
                check={check}
                cancel={true}
                edit={false}
                remove={false}
                onSave={()=>handleSave()}
                onCancel={()=>handleCancel()}/>
        </>
    )
}

RowAddEditText.propTypes =  {
    dataTestKey:PropTypes.string.isRequired,
    dataTestPrefix:PropTypes.string.isRequired,
    initialValue:PropTypes.object,
    save:PropTypes.bool.isRequired,
    onChange:PropTypes.func,
    onSave:PropTypes.func,
    onCancel:PropTypes.func,
    creation:PropTypes.bool.isRequired
}
import { TextParamType } from './TextParamType'

export class TextParam {
  component
  type

  constructor(
    component = '',
    type = TextParamType.NULL,
  ) {
    this.component = component
    this.type = type
  }

  static NULL = new TextParam()
}
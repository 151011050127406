export class TextParamType {
  name
  label

  constructor(name = '', label = '') {
    this.name = name
    this.label = label ? label : name
  }

  static NULL = new TextParamType()
  static IGNORE = new TextParamType('IGNORE', 'ignore')
  static LEGEND = new TextParamType('LEGEND', 'legend')
  static REMOVE_BLOCK = new TextParamType('REMOVE_BLOCK', 'détextualiser')
  static ALL = [TextParamType.NULL, TextParamType.IGNORE, TextParamType.LEGEND, TextParamType.REMOVE_BLOCK]
  static fromStr = str => str ? TextParamType.ALL.find(f => f.name.toUpperCase()  === str.toUpperCase()) : str
}
import { SET_CC_CHILD_REFERENTIEL, SET_CC_REFERENTIEL } from '../reducers/convention-collective-reducer'
import { SET_REFERENTIELS, SET_REFERENTIEL } from '../reducers/referentiels-reducer'
import { setLoader } from './loader-action'
import getPayeService from '../../api/envService'
import { REFERENTIEL_ENDPOINT } from '../../api/endpoints'
import Referentiel from '../constants/referentiel'
import { buildReferentielValeursFromJSON } from './tableur-action'

export const setReferentiels = referentiels => {
  return {
    type: SET_REFERENTIELS,
    value: referentiels,
  }
}

export const setReferentiel = referentiel => {
  return {
    type: SET_REFERENTIEL,
    value: referentiel,
  }
}

export const loadReferentielsTablePage = setInit => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(getAllReferentiels()))
    .then(() => !!setInit && setInit(true))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))

export const createReferentiel = (referentiel, after) => dispatch => {
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().post(REFERENTIEL_ENDPOINT, referentiel))
    .then(() => after && after())
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const getReferentielById = id => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getById(REFERENTIEL_ENDPOINT, id))
    .then(
      data =>
        new Referentiel(
          data.name ? data.name : data.nom,
          data.description,
          buildReferentielValeursFromJSON(data.values ? data.values : data.valeurs)
        )
    )
    .then(referentiel => dispatch(setReferentiel(referentiel)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const updateReferentiel = referentiel => dispatch => {
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().put(REFERENTIEL_ENDPOINT, referentiel, referentiel.nom))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

function loadAllRefs() {
  return getPayeService()
    .get(REFERENTIEL_ENDPOINT)
    .then(datas => {
      return datas.map(
        data =>
          new Referentiel(
            data.name ? data.name : data.nom,
            data.description,
            buildReferentielValeursFromJSON(data.values ? data.values : data.valeurs)
          )
      )
    })
}

export const getAllReferentiels = () => dispatch => {
  dispatch(setLoader(true))
  return loadAllRefs()
    .then(referentiels => dispatch(setReferentiels(referentiels)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const setCCReferentiel = referentiel => {
  return {
    type: SET_CC_REFERENTIEL,
    value: referentiel,
  }
}

export const setCCChildReferentiel = (component, referentiel) => {
  return {
    type: SET_CC_CHILD_REFERENTIEL,
    value: { component, referentiel },
  }
}

export const getCCReferentielById = id => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getById(REFERENTIEL_ENDPOINT, id))
    .then(
      data =>
        new Referentiel(
          data.name ? data.name : data.nom,
          data.description,
          buildReferentielValeursFromJSON(data.values ? data.values : data.valeurs)
        )
    )
    .then(referentiel => dispatch(setCCReferentiel(referentiel)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const getCCChildReferentielById = component => dispatch => {
  const id = component.referentiel
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getById(REFERENTIEL_ENDPOINT, id))
    .then(
      data =>
        new Referentiel(
          data.name ? data.name : data.nom,
          data.description,
          buildReferentielValeursFromJSON(data.values ? data.values : data.valeurs)
        )
    )
    .then(referentiel => dispatch(setCCChildReferentiel(component, referentiel)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const patchReferentielValues = (idRef, valuesMap) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() =>
      Array.from(valuesMap).map(val => {
        return { code: val[1].code, libelle: val[1].libelle, text: val[1].text, defaultValue: val[1].defaultValue }
      })
    )
    .then(values => getPayeService().patch(REFERENTIEL_ENDPOINT, idRef, 'values', values))
    .then(() => dispatch(getCCReferentielById(idRef)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

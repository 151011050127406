import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { AddItemButtons } from '../../../../conventions-collectives/themes/add-item-buttons'
import { hasIndex } from '../blocks/block-textualisation-popper-content'
import { TextParamType } from '../../../../../store/constants/TextParamType'
import { TextParam } from '../../../../../store/constants/TextParam'

const selectedStyle = { width: 276, backgroundColor: '#3f51b5', color: 'white', fontWeight: 'bold' }
const standardStyle = { width: 276 }

const selectedStyleCC = { width: 184, backgroundColor: '#3f51b5', color: 'white', fontWeight: 'bold' }
const standardStyleCC = { width: 184 }

const IGNORE = TextParamType.IGNORE
const LEGEND = TextParamType.LEGEND
const REMOVE_BLOCK = TextParamType.REMOVE_BLOCK

export function ModelTextualisationPopperContent({
                                                   className,
                                                   parameters = [],
                                                   handleCancel,
                                                   handleSave,
                                                   idx,
                                                   fromCC
                                                 }) {
  const storeComps = useSelector(state => state.componentModule.components)
  const [selectedType, setSelectedType] = useState(TextParamType.IGNORE)
  const [selectedComp, setSelectedComp] = useState('')

  useEffect(() => {
    if (!hasIndex(idx)) return
    setSelectedType(parameters[idx].type)
    setSelectedComp(parameters[idx].component)
  }, [idx, parameters])

  const innerHandleSave=  () => {
    handleSave(new TextParam(selectedComp, selectedType), idx)
  }

  let isIgnore = selectedType === IGNORE
  let isLegend = selectedType === LEGEND
  let isRemoveBlock = selectedType === REMOVE_BLOCK
  let onChangeType = (type) => setSelectedType(type)

  const allComp = useMemo(() => storeComps.filter(
    c => parameters && !parameters.find(p => p.component === c.nom)),
    [parameters]
  )

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction='column'>
          <Grid>
            <Autocomplete
              data-test='ref_composant'
              options={allComp}
              getOptionLabel={c => c.nom}
              style={{ width: '100%' }}
              onChange={(e, value) => {setSelectedComp(value ? value.nom : '')}}
              renderInput={params => <TextField {...params}
                                                value={params.inputProps.value}
                                                label='Selectionner un composant' />}
            />
          </Grid>
          <Grid>
            <form className={className}>
              <ToggleButtonGroup exclusive color='primary'>
                <ToggleButton style={isIgnore ? fromCC ? selectedStyleCC : selectedStyle : fromCC ? standardStyleCC : standardStyle}
                              selected={isIgnore}
                              value={"IGNORE"}
                              onClick={() => onChangeType(IGNORE)}>
                  <div>Ignore</div>
                </ToggleButton>
                <ToggleButton style={isLegend ? fromCC ? selectedStyleCC : selectedStyle : fromCC ? standardStyleCC : standardStyle}
                              selected={isLegend}
                              value={"LEGEND"}
                              onClick={() => onChangeType(LEGEND)}>Légende</ToggleButton>
                {fromCC && <ToggleButton style={isRemoveBlock ? fromCC ? selectedStyleCC : selectedStyle : fromCC ? standardStyleCC : standardStyle}
                              selected={isRemoveBlock}
                              value={"REMOVE_BLOCK"}
                              onClick={() => onChangeType(REMOVE_BLOCK)}>Détextualiser</ToggleButton>}
              </ToggleButtonGroup>
            </form>
          </Grid>
        </Grid>
      </Grid>

      <AddItemButtons
        onClick={() => {
        }}
        onSave={innerHandleSave}
        onCancel={handleCancel}
        showList={true}
        addDataTest=''
        cancelDataTest={'ANNULER'}
        saveDataTest={'AJOUTER'}
        isSaveDisabled={!selectedComp}
      />
    </>

  )
}
